:global(.ant-tooltip-inner .font-icon) {
    margin-right: 8px;
}

.side-menu {
    height: 100%;
    padding-right: 1px;

    & > ul {
        height: 100%;
    }

    :global {
        .font-icon {
            margin-right: 8px;
        }

        .ant-menu {
            border-right: 0;
        }

        .ant-menu-item::after {
            display: none;
        }

        .ant-menu-item.ant-menu-item-selected {
            &::after {
                display: block;
                left: 0;
                right: auto;
            }
        }
    }

    .menu-text {
        transition: all 300ms;
    }
}

@primary-color: #2F54EB;@link-color: @primary-color;@success-color: #52c41a;@warning-color: #faad14;@error-color: #f5222d;@font-size-base: 12px;@heading-color: rgba(0, 0, 0, .85);@text-color: rgba(0, 0, 0, .65);@text-color-secondary : rgba(0, 0, 0, .45);@disabled-color : rgba(0, 0, 0, .25);@border-radius-base: 4px;@border-color-base: #d9d9d9;@box-shadow-base: 0 2px 8px rgba(0, 0, 0, .15);@layout-header-background: #001529;@menu-dark-submenu-bg: lighten(@layout-header-background, 5%);@input-height-base: 28px;@input-height-lg: 40px;@input-height-sm: 24px;@btn-height-base: 28px;@table-padding-vertical: 8px;@table-padding-horizontal: 8px;@menu-inline-toplevel-item-height: 30px;@menu-item-height: 30px;